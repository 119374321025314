import React from "react";
import { Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";

const SpaceConfiguration = () => {
  return (
    <section className="spaces">
      <div className="container">
        <h5 className="h6-color text-center">Work spaces</h5>
        <h2 className="mb-3">
          Building <span className="active-color">A Better Workspace:</span> Our
          Mission And Values
        </h2>
        {/* <p className="spaces-p">
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry. Lorem Ipsum has been the industry's standard dummy text.
        </p> */}
        <div className="row p-0 d-flex">
          <div className="col-lg-7 col-md-7 col-sm-12" data-aos="fade-down">
            <figure>
              <img src="assets/images/spaces/open-desk.png" alt="work" />
            </figure>
          </div>
          <div className="col-lg-5 col-md-5 col-sm-12">
            <div className="spaces-card1 workspace-card">
              <h3>Open Desk</h3>
              <p>
                <strong>Duration Available:</strong> 1 Week, 1 Month, Customised
                Duration.
              </p>
              <p>
                <strong>About: </strong>Enjoy the flexibility of a workspace
                without the long-term commitment. Perfect for freelancers and
                remote workers.
              </p>
              <p>
                <strong>Inclusions:</strong> Free Coffee/Tea, Food Chargeable.
              </p>
              <p>
                <strong>Amenities:</strong> Quiet Space, Video Conference,
                Enhanced HVAC standards, Enhanced cleaning services, Hand
                Sanitizers, Tea/Coffee Point, Standing Desk, Printer*, IT
                services*, Server rooms*, 24/7 security, Mail and package
                handling, Internet, Conference room*, Pantry, Locker Storage
                facility*, Dedicated parking for 4 wheeler & 2 Wheeler, Onsite
                staff.
              </p>
              {/* <div className="d-flex justify-content-between flex-lg-row flex-md-column flex-column gap-3 price">
                <div className="d-flex">
                  <h6>
                    <i className="fa-regular fa-circle-dot"></i> $200
                  </h6>
                  <p> / Per Month</p>
                </div>
                <div className="d-flex">
                  <h6>
                    <i className="fa-regular fa-circle-dot"></i> $14
                  </h6>
                  <p> / Per Day</p>
                </div>
              </div> */}
              <div className="seat mt-4">
                <Link to="/contact#contact-form" className="hover2">
                  Enquire Now!
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="row p-1 position-relative">
          <div className="spaces-circle1" data-aos="zoom-in-down"></div>
          <div className="spaces-circle2" data-aos="zoom-in-down"></div>
          <div className="col-lg-5 col-md-5 col-sm-12 spaces-card-2">
            <div className="spaces-card2 workspace-card">
              <h3>Meeting Room</h3>
              <p>
                <strong>Duration Available:</strong> 1 Hour, 1 Day, 1 Week, 1
                Month, Customised Duration.
              </p>
              <p>
                <strong>About: </strong>Impress clients and collaborate
                effectively in our professional meeting rooms.
              </p>
              <p>
                <strong>Inclusions:</strong> Coffee/Tea, Food Chargeable.
              </p>
              <p>
                <strong>Amenities:</strong> Quiet Space, Video Conference,
                Enhanced HVAC standards, Enhanced cleaning services, Hand
                Sanitizers, Tea/Coffee Point, Printer*, IT services*, Server
                rooms*, 24/7 security, Internet, Pantry, Dedicated parking for 4
                wheeler & 2 Wheeler, Onsite staff.
              </p>
              <div className="seat mt-4">
                <Link to="/contact#contact-form" className="hover2">
                  Enquire Now!
                </Link>
              </div>
            </div>
          </div>
          <div
            className="col-lg-7 col-md-7 col-sm-12 spaces-image"
            data-aos="fade-up"
          >
            <figure>
              <img src="assets/images/spaces/meeting-room.png" alt="work" />
            </figure>
          </div>
        </div>

        <div className="row p-0 d-flex my-5">
          <div className="col-lg-7 col-md-7 col-sm-12" data-aos="fade-down">
            <figure>
              <img src="assets/images/spaces/conference-room.png" alt="work" />
            </figure>
          </div>
          <div className="col-lg-5 col-md-5 col-sm-12">
            <div className="spaces-card1 workspace-card">
              <h3>Conference Room</h3>
              <p>
                <strong>Duration Available:</strong> 1 Hour, 1 Day, 1 Week, 1
                Month, Customised Duration.
              </p>
              <p>
                <strong>About: </strong>Have a board meeting or huge team
                meeting we have just the right space for you with our Conference
                Room.
              </p>
              <p>
                <strong>Inclusions:</strong> Coffee/Tea, Food Chargeable.
              </p>
              <p>
                <strong>Amenities:</strong> Quiet Space, Video Conference,
                Enhanced HVAC standards, Enhanced cleaning services, Hand
                Sanitizers, Tea/Coffee Point, Printer*, IT services*, Server
                rooms*, 24/7 security, Internet, Pantry, Dedicated parking for 4
                wheeler & 2 Wheeler, Onsite staff.
              </p>
              <div className="seat mt-4">
                <Link to="/contact#contact-form" className="hover2">
                  Enquire Now!
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className="row mb-1 d-flex">
          <div
            className="col-lg-4 col-md-4 d-flex"
            data-aos="flip-left"
            data-aos-easing="ease-out-cubic"
            data-aos-duration="2000"
          >
            <div className="Spaces-card">
              <figure>
                <img src="assets/images/spaces/cabin.png" alt="image" />
              </figure>
              <div className="card-body p-3  workspace-card">
                <div>
                  <h4>Cabins</h4>
                  <p>
                    <strong>Duration Available:</strong> 1 Hour, 1 Day, 1 Week,
                    1 Month, Customised Duration Packages.
                  </p>
                  <p>
                    <strong>About:</strong> Need more privacy? Our private
                    cabins offer a quiet and focused environment for individuals
                    or small teams.
                  </p>
                  <p>
                    <strong>Inclusions:</strong> Coffee/Tea, Food Chargeable.
                  </p>
                  <p>
                    <strong>Amenities:</strong> Quiet Space, Video Conference,
                    Enhanced HVAC standards, Enhanced cleaning services, Hand
                    Sanitizers, Tea/Coffee Point, Standing Desk, Printer*, IT
                    services*, Server rooms*, 24/7 security, Mail and package
                    handling, Internet, Conference room*, Pantry, Locker Storage
                    facility*, Dedicated parking for 4 wheeler & 2 Wheeler,
                    Onsite staff.
                  </p>
                </div>
                <div>
                  <div className="seat mt-4">
                    <Link to="/contact#contact-form" className="hover2">
                      Enquire Now!
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className="col-lg-4 col-md-4 d-flex"
            data-aos="flip-left"
            data-aos-easing="ease-out-cubic"
            data-aos-duration="2000"
          >
            <div className="Spaces-card">
              <figure>
                <img src="assets/images/spaces/event-space.jpg" alt="Image" />
              </figure>
              <div className="card-body p-3 workspace-card">
                <div>
                  <div style={{ paddingBottom: "15px" }}>
                    <h4 style={{ padding: 0 }}>Event Space</h4>
                    <span>(Conference/Seminars/Workshops)</span>
                  </div>

                  <p>
                    <strong>Duration Available:</strong> Customised Plan.
                  </p>
                  <p>
                    <strong>About:</strong> Our spacious and well-equipped
                    facilities are perfect for hosting seminars, workshops, and
                    training sessions. We offer flexible rental options to
                    accommodate your event needs. We can provide additional
                    services such as catering, audiovisual equipment, and
                    on-site support.
                  </p>
                </div>
                <div>
                  <div className="seat mt-4">
                    <Link to="/contact#contact-form" className="hover2">
                      Enquire Now!
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className="col-lg-4 col-md-4 d-flex"
            data-aos="flip-left"
            data-aos-easing="ease-out-cubic"
            data-aos-duration="2000"
          >
            <div className="Spaces-card">
              <figure>
                <img src="assets/images/spaces/exam-center.jpg" alt="Image" />
              </figure>
              <div className="card-body p-3  workspace-card">
                <div>
                  <h4>Exam Center</h4>
                  <p>
                    <strong>Duration Available:</strong> Customised Plan.
                  </p>
                  <p>
                    <strong>About:</strong> Our spacious and well-equipped
                    facilities are perfect for setting up an exam centre. We
                    offer flexible rental options to accommodate your needs. We
                    can provide additional services such as laptops, audiovisual
                    equipment, and on-site IT support.
                  </p>
                </div>
                <div>
                  <div className="seat mt-4">
                    <Link to="/contact#contact-form" className="hover2">
                      Enquire Now!
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SpaceConfiguration;
