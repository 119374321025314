import React from "react";
import withLayout from "../hoc/withLayout";
import Hero from "../components/Hero";
import Features from "../components/Features";
import OurBenefits from "../components/OurBenefits";
import Gallery from "../components/Gallery";
import Solution from "../components/Solution";
import Testimonials from "../components/Testimonials";
import FaqQuestions from "../components/FaqQuestions";
import useMetaPixel from "../hooks/useMetaPixel";
import SlidingGallery from "../components/SlidingGallery";

const Home = () => {
  useMetaPixel();
  return (
    <>
      <div class="bg-circle"></div>
      <Hero />
      <Features />
      <OurBenefits />
      {/* <Gallery /> */}
      <SlidingGallery />
      <Solution />
      <Testimonials />
      <FaqQuestions />
    </>
  );
};

export default withLayout(Home);
