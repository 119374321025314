// src/hooks/useMetaPixel.js
import { useEffect } from "react";

const useMetaPixel = () => {
  useEffect(() => {
    // Add the Meta Pixel script
    const script = document.createElement("script");
    script.innerHTML = `
      !function(f,b,e,v,n,t,s)
      {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
      n.callMethod.apply(n,arguments):n.queue.push(arguments)};
      if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
      n.queue=[];t=b.createElement(e);t.async=!0;
      t.src=v;s=b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t,s)}(window, document,'script',
      'https://connect.facebook.net/en_US/fbevents.js');
      fbq('init', '3869540066662246');
      fbq('track', 'PageView');
    `;
    document.head.appendChild(script);

    // Add the noscript tag
    const noscript = document.createElement("noscript");
    noscript.innerHTML = `
      <img height="1" width="1" style="display:none"
      src="https://www.facebook.com/tr?id=3869540066662246&ev=PageView&noscript=1"
      />
    `;
    document.body.appendChild(noscript);

    // Cleanup function to remove the script and noscript tags
    return () => {
      document.head.removeChild(script);
      document.body.removeChild(noscript);
    };
  }, []);
};

export default useMetaPixel;
