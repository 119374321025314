// src/components/SlidingGallery.jsx
import React, { useState } from "react";
import CustomSwiper from "./CustomSwiper";
import { Autoplay } from "swiper/modules";
import "bootstrap/dist/css/bootstrap.min.css"; // Ensure Bootstrap styles are included

const SlidingGallery = () => {
  const [showModal, setShowModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");

  const handleSlideClick = (image) => {
    setSelectedImage(image);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedImage("");
  };

  const swiperSettings = {
    spaceBetween: 10,
    slidesPerView: 3,
    slidesPerGroup: 3,
    pagination: { clickable: true },
    autoplay: {
      delay: 3000, // Auto slideshow delay in milliseconds
      disableOnInteraction: false,
    },
    breakpoints: {
      0: {
        slidesPerView: 1,
        slidesPerGroup: 1,
        spaceBetween: 10,
      },
      480: {
        slidesPerView: 1,
        slidesPerGroup: 1,
        spaceBetween: 10,
      },
      768: {
        slidesPerView: 2,
        slidesPerGroup: 2,
        spaceBetween: 20,
      },
      1024: {
        slidesPerView: 3,
        slidesPerGroup: 3,
        spaceBetween: 30,
      },
      1280: {
        slidesPerView: 4,
        slidesPerGroup: 4,
        spaceBetween: 40,
      },
      1440: {
        slidesPerView: 4,
        slidesPerGroup: 4,
        spaceBetween: 50,
      },
    },
  };

  const slides = [
    "gallery1.jpg",
    "gallery2.jpeg",
    "gallery3.jpeg",
    "gallery4.jpeg",
    "gallery5.jpg",
    "gallery6.jpg",
    "gallery7.jpg",
    "gallery9.jpeg",
    "gallery10.jpg",
    "gallery11.jpg",
    "image00001.jpeg",
    "image00002.jpeg",
    "image00003.jpeg",
    "image00005.jpeg",
    "image00006.jpeg",
    "image00010.jpeg",
    "image00011.jpeg",
    "image00013.jpeg",
    "image00014.jpeg",
    "image00016.jpeg",
    "image00017.jpeg",
    "image00018.jpeg",
    "image00021.jpeg",
    "image00022.jpeg",
  ].map((image, index) => (
    <div
      key={index}
      className="sliding-gallery-img"
      onClick={() => handleSlideClick(image)}
    >
      <img
        src={`assets/images/gallery/${image}`}
        alt={`Gallery Image ${index + 1}`}
      />
    </div>
  ));

  const additionalModules = [Autoplay];

  return (
    <>
      <section className="gallery">
        <div className="container">
          <h5 className="text-center h6-color">Get Inspired</h5>
          <h2 className="mb-3">
            Visualize Your{" "}
            <span className="active-color">
              Ideal <br /> Workspace :{" "}
            </span>
            Explore Our Gallery
          </h2>

          <CustomSwiper
            settings={swiperSettings}
            slides={slides}
            additionalModules={additionalModules}
          />
        </div>
      </section>

      {/* Bootstrap Modal */}
      <div
        className={`modal fade ${showModal ? "show" : ""}`}
        tabIndex="-1"
        role="dialog"
        style={{
          display: showModal ? "block" : "none",
        }}
        onClick={handleCloseModal}
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-body">
              <img
                src={`assets/images/gallery/${selectedImage}`}
                alt="Selected"
                className="img-fluid"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "contain",
                }}
              />
            </div>
          </div>
        </div>
      </div>
      {showModal && <div className="modal-backdrop fade show"></div>}
    </>
  );
};

export default SlidingGallery;
