import React from "react";

const amenities = [
  {
    name: "Quiet Space",
    icon: "https://fonts.gstatic.com/s/i/short-term/release/materialsymbolsoutlined/hearing_disabled/default/24px.svg",
  },
  {
    name: "Video Conference",
    icon: "https://fonts.gstatic.com/s/i/short-term/release/materialsymbolsoutlined/hangout_video/default/24px.svg",
  },
  {
    name: "Enhanced HVAC standards",
    icon: "https://fonts.gstatic.com/s/i/short-term/release/materialsymbolsoutlined/ac_unit/default/24px.svg",
  },
  {
    name: "Enhanced cleaning services",
    icon: "https://fonts.gstatic.com/s/i/short-term/release/materialsymbolsoutlined/cleaning_services/default/24px.svg",
  },
  {
    name: "Hand Sanitizers",
    icon: "https://fonts.gstatic.com/s/i/short-term/release/materialsymbolsoutlined/sanitizer/default/24px.svg",
  },
  {
    name: "Tea/Coffee Point",
    icon: "https://fonts.gstatic.com/s/i/short-term/release/materialsymbolsoutlined/emoji_food_beverage/default/24px.svg",
  },
  {
    name: "Standing Desk",
    icon: "https://fonts.gstatic.com/s/i/short-term/release/materialsymbolsoutlined/desk/default/24px.svg",
  },
  {
    name: "Printer",
    icon: "https://fonts.gstatic.com/s/i/short-term/release/materialsymbolsoutlined/print/default/24px.svg",
  },
  {
    name: "IT services",
    icon: "https://fonts.gstatic.com/s/i/short-term/release/materialsymbolsoutlined/network_manage/default/24px.svg",
  },
  {
    name: "Server rooms",
    icon: "https://fonts.gstatic.com/s/i/short-term/release/materialsymbolsoutlined/dns/default/24px.svg",
  },
  {
    name: "24/7 security",
    icon: "https://fonts.gstatic.com/s/i/short-term/release/materialsymbolsoutlined/shield_locked/default/24px.svg",
  },
  {
    name: "Mail and package handling",
    icon: "https://fonts.gstatic.com/s/i/short-term/release/materialsymbolsoutlined/package_2/default/24px.svg",
  },
  {
    name: "Internet",
    icon: "https://fonts.gstatic.com/s/i/short-term/release/materialsymbolsoutlined/wifi/default/24px.svg",
  },
  {
    name: "Conference room",
    icon: "https://fonts.gstatic.com/s/i/short-term/release/materialsymbolsoutlined/podium/default/24px.svg",
  },
  {
    name: "Pantry",
    icon: "https://fonts.gstatic.com/s/i/short-term/release/materialsymbolsoutlined/restaurant/default/24px.svg",
  },
  {
    name: "Locker Storage facility",
    icon: "https://fonts.gstatic.com/s/i/short-term/release/materialsymbolsoutlined/inventory_2/default/24px.svg",
  },
  {
    name: "Dedicated parking for 4 wheeler & 2 Wheeler",
    icon: "https://fonts.gstatic.com/s/i/short-term/release/materialsymbolsoutlined/local_parking/default/24px.svg",
  },
  {
    name: "Onsite staff",
    icon: "https://fonts.gstatic.com/s/i/short-term/release/materialsymbolsoutlined/person_apron/default/24px.svg",
  },
];

const Amenities = () => {
  return (
    <section className="amenities-section" data-aos="fade-down">
      <div className="container amenities-grid-container">
        <h2 className="text-center">Amenities</h2>
        <div className="amenities-grid">
          {amenities.map((amenity, index) => (
            <div key={index} className="amenity-item">
              <img src={amenity.icon} alt={amenity.name} />
              <span>{amenity.name}</span>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Amenities;
