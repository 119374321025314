import React from "react";
import { Link } from "react-router-dom";

const OurBenefits = () => {
  return (
    <section className="workspaces">
      <div className="work-bg">
        <div className="container">
          <div className="row">
            <div
              className="col-lg-6 col-md-6 position-relative d-flex flex-column align-items-center justify-content-center"
              data-aos="fade-up"
            >
              <div className="d-flex align-items-start flex-column">
                <div className="work-circle"></div>
                <div className="work-image1">
                  <figure>
                    <img
                      src="assets/images/work/work-image1.png"
                      alt="work-image1"
                    />
                  </figure>
                </div>
              </div>
              <div className="d-flex align-items-center justify-content-end mt-4">
                <div className="ture">
                  <h5>Do you like our features?</h5>
                  <div className="d-flex mt-3">
                    <Link to="/contact#contact-form" className="hover1">
                      Book A Tour
                    </Link>
                  </div>
                </div>
                <div className="work-image2">
                  <figure>
                    <img
                      src="assets/images/work/work-image2.png"
                      alt="work-image2"
                    />
                  </figure>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6" data-aos="fade-down">
              <h5 className="h6-color text-lg-start text-md-start text-sm-center text-center">
                Our benefits
              </h5>
              <h2>
                Our <span>Workspaces</span> that Are Designed With Your Business
                In Mind
              </h2>
              {/* <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took.
              </p> */}
              <div className="d-flex flex-column banifits">
                <h6 id="work-fanifits">
                  <strong>1. Tailored to Your Needs:</strong> From private
                  cabins to shared desks, we offer a variety of workspace
                  options to suit your individual or team's preferences.
                </h6>
                <h6>
                  <strong>2. Flexible Lease Terms:</strong> Whether you need a
                  short-term solution or a long-term commitment, our flexible
                  lease terms ensure you can find the perfect fit.
                </h6>
                <h6>
                  <strong>3. Scalable Spaces:</strong> As your business grows,
                  we can easily accommodate your changing needs with scalable
                  workspace options.
                </h6>
                <h6>
                  <strong>4. Hassle-Free Setup:</strong> Move in with ease
                  thanks to our fully furnished and equipped workspaces.
                </h6>
                <h6>
                  <strong>5. Personalised Service:</strong> Our dedicated team
                  is committed to providing exceptional customer service and
                  ensuring your satisfaction.
                </h6>
              </div>

              <div className="d-flex mt-4">
                <Link to="/spaces" className="hover1">
                  Know More
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default OurBenefits;
