import React from "react";
import { Link } from "react-router-dom"; // Import Link for internal navigation

const Hero = () => {
  return (
    <section className="hero">
      <div className="container">
        <div className="row">
          {/* Left Side Content */}
          <div
            className="col-lg-6 col-md-6 d-flex flex-column justify-content-center"
            data-aos="fade-down"
          >
            <h1>
              Find Your Ideal <span className="active-color">Workspace</span> at
              BestWorks
            </h1>
            <p className="hero-p">
              Find your perfect workspace at BestWorks, a leading coworking
              space in Kondhwa, Pune. Our flexible workspaces are ideal for
              freelancers, startups, and professionals seeking a modern and
              productive environment. Enjoy spacious facilities, high-speed
              internet, 24/7 security, and a supportive community.
            </p>
            <div className="d-flex gap-3 justify-content-lg-start justify-content-md-start justify-content-sm-center justify-content-center">
              <Link to="/contact#contact-form" className="hover1">
                Book A Tour
              </Link>
              <Link to="/spaces" className="hover2">
                Book a Space
              </Link>
            </div>

            {/* User Rating Section */}
            {/* <div className="user-rating d-flex justify-content-lg-start justify-content-md-center justify-content-center">
              <div className="d-flex flex-column align-items-center">
                <div>
                  <span className="count">10</span>
                  <span>K</span>
                </div>
                <p className="pt-1">Active User</p>
              </div>
              <hr />
              <div className="d-flex flex-column align-items-center ms-1">
                <span className="count">4.9</span>
                <p className="pt-1">Average rating</p>
              </div>
            </div> */}
          </div>

          {/* Right Side Content */}
          <div
            className="col-lg-6 col-md-6 position-relative hero-right"
            data-aos="fade-up"
          >
            <div className="hero-circle"></div>
            <div className="hero-image1">
              <figure>
                <img
                  src="assets/images/hero/hero-image1.png"
                  alt="hero-image1"
                />
              </figure>
            </div>
            <div className="hero-image2">
              <figure>
                <img
                  src="assets/images/hero/hero-image2.png"
                  alt="hero-image2"
                />
              </figure>
            </div>

            {/* Facilities Section */}
            <div className="hero-facility">
              <div className="d-flex align-items-center justify-content-center">
                <span>5500</span>
                <p>sq ft per floor</p>
              </div>
              <div className="d-flex align-items-center justify-content-center">
                <span>170+</span>
                <p>Seats</p>
              </div>
              <div className="d-flex align-items-center justify-content-center">
                <span> 17+</span>
                <p>Amenities</p>
              </div>
            </div>

            {/* Engaging Community Section */}
            <div className="Community">
              <p>Engaging Community</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
