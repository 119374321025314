import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import AOS from "aos";
import "bootstrap/dist/css/bootstrap.min.css"; // Bootstrap CSS
import "aos/dist/aos.css"; // AOS CSS
import "@fortawesome/fontawesome-free/css/all.min.css"; // Font Awesome CSS
import "./css/App.css";
import "./css/AppResponsive.css";
import "aos/dist/aos.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Home from "./pages/Home";
import About from "./pages/About";
import Spaces from "./pages/Spaces";
import Contact from "./pages/Contact";
import FAQ from "./pages/FAQ";
import ScrollToTop from "./utils/ScrollToTop";
import NotFound from "./pages/NotFound";

function App() {
  useEffect(() => {
    AOS.init({
      once: true,
      duration: 1500,
    });
  }, []);

  return (
    <Router>
      <ScrollToTop>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/about" element={<About />} />
          <Route path="/spaces" element={<Spaces />} />
          <Route path="/compliance" element={<FAQ />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </ScrollToTop>
    </Router>
  );
}

export default App;
