// src/hoc/withLayout.js
import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";

// HOC function that takes a component as an argument
const withLayout = (WrappedComponent) => {
  return (props) => (
    <>
      {/* Render Header */}
      <Header />

      {/* Render the passed-in component */}
      <WrappedComponent {...props} />

      {/* Render Footer */}
      <Footer />
    </>
  );
};

export default withLayout;
