// src/pages/NotFound.jsx
import React from "react";
import { Link } from "react-router-dom";

const NotFound = () => {
  return (
    <div className="site-wrapper">
      <section className="page-404">
        <div className="container">
          <div className="d-flex flex-column align-items-center justify-content-center text-center">
            <figure>
              <img
                src="assets/images/404/404-page.png"
                alt="Page-Not"
                style={{
                  width: "100%",
                  maxWidth: "250px",
                }}
              />
            </figure>
            <h2>Ooops...</h2>
            <h2 className="active-color">Page Not Found</h2>
            <p className="m-0">
              Sorry, the page you are looking for does not exist. It might have
              been moved or deleted.
            </p>
            <div>
              <Link to="/" className="hover2">
                Back To Home
              </Link>
            </div>
            <div className="d-flex align-items-center justify-content-center mt-1">
              <i className="fa-brands fa-facebook"></i>
              <i className="fa-brands fa-instagram"></i>
              <i className="fa-brands fa-twitter"></i>
              <i className="fa-brands fa-linkedin"></i>
            </div>
            <div className="copyright d-flex align-items-center justify-content-center container">
              <p>Copyright © 2024 Bestworks Co-working Spaces</p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default NotFound;
