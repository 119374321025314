import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isSideNavOpen, setIsSideNavOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false); // Manage dropdown state

  // Function to toggle the side navigation
  const openAside = () => {
    setIsSideNavOpen(true);
  };

  const closeAside = () => {
    setIsSideNavOpen(false);
  };

  // Toggle dropdown inside the aside menu
  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleSpaceClick = () => {
    navigate("/contact#contact-form");
    if (location.pathname === "/contact") {
      document.getElementById("contact-form").scrollIntoView({
        behavior: "smooth",
      });
    }
  };

  return (
    <header>
      <nav className="container navbar navbar-expand-lg">
        <div className="container-fluid">
          <Link className="nav-logo p-0" to="/">
            <img src="/assets/images/logo.svg" alt="logo" />
          </Link>
          <button className="open-aside" onClick={openAside} type="button">
            <i className="fa-solid fa-bars"></i>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav d-flex justify-content-center align-items-center gap-lg-4 gap-md-2 gap-sm-2 gap-2 mb-2 mb-lg-0">
              <li className="nav-item">
                <Link className="nav-link" to="/">
                  Home
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/about">
                  About Us
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/spaces">
                  Spaces
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/contact">
                  Contact Us
                </Link>
              </li>
            </ul>
          </div>
          <button
            className="join-btn hover1"
            type="button"
            onClick={handleSpaceClick}
          >
            Book a Space now
          </button>
        </div>
      </nav>

      {/* Conditional rendering of the aside navigation */}
      {isSideNavOpen && (
        <aside>
          <div id="mySidenav" className="right-sidbar" style={{ left: "0" }}>
            <div className="side-nav-logo d-flex justify-content-between align-items-center ps-2 pe-1 nav-logo">
              <div className="navbar-brand">
                <Link to="/">
                  <img src="/assets/images/logo.svg" alt="Logo" />
                </Link>
              </div>
              <button
                className="fa-solid fa-xmark"
                onClick={closeAside}
              ></button>
            </div>
            <ul>
              <li className="nav-item">
                <Link to="/">Home</Link>
              </li>
              <li className="nav-item">
                <Link to="/about">About</Link>
              </li>
              <li className="nav-item">
                <Link to="/spaces">Spaces</Link>
              </li>
              {/* <li className="nav-item">
                <Link to="/gallery">Gallery</Link>
              </li> */}
              <li className="nav-item">
                <Link to="/contact">Contact</Link>
              </li>
              {/* <li className="nav-item"> */}
              {/* <div
                  className="d-flex align-items-center justify-content-between"
                  id="slid-btn"
                  onClick={toggleDropdown}
                >
                  <button className="a-slid page-btn">Pages</button>
                  <i className="fa-solid fa-caret-down pe-2"></i>
                </div> */}
              {/* Conditional rendering for dropdown */}
              {/* {isDropdownOpen && (
                  <ul id="slid-drop" className="aside-dropdwon">
                    <li>
                      <Link to="/find-space">Find a Space Near</Link>
                    </li>
                    <li>
                      <Link to="/testimonials">Testimonials</Link>
                    </li>
                    <li>
                      <Link to="/latest-news">Latest News</Link>
                    </li>
                    <li>
                      <Link to="/pricing">Pricing</Link>
                    </li>
                    <li>
                      <Link to="compliance">FAQ</Link>
                    </li>
                    <li>
                      <Link to="/404">404</Link>
                    </li>
                    <li>
                      <Link to="/coming-soon">Coming Soon</Link>
                    </li>
                  </ul>
                )} */}
              {/* </li> */}
            </ul>
          </div>
        </aside>
      )}
    </header>
  );
};

export default Header;
