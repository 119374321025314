import React from "react";
import withLayout from "../hoc/withLayout";
import Features from "../components/Features";
import Amenities from "../components/Amenities";
import { Link } from "react-router-dom";
import useMetaPixel from "../hooks/useMetaPixel";

const About = () => {
  useMetaPixel();
  return (
    <div>
      <div className="banner">
        <div className="container">
          <h2>ABOUT US</h2>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/">Home</Link>
              </li>
              <li className="breadcrumb-item" aria-current="page">
                About
              </li>
            </ol>
          </nav>
        </div>
      </div>
      {/* ======== End of 2.1. banner section ======== */}
      {/* ========  2.2. hero-about section ======== */}
      <section className="hero-about">
        <div className="container">
          <div className="row">
            <div
              className="col-lg-6 col-md-6 d-flex flex-column justify-content-center"
              data-aos="fade-down"
            >
              <h5 className="h6-color">About Us</h5>
              <h2>
                Your Ideal Workspace Awaits:{" "}
                <span className="active-color">Discover</span> BestWorks
              </h2>
              <p className="hero-about-p">
                Experience the perfect blend of comfort, productivity, and
                community at BestWorks. Our modern coworking space in Kondhwa,
                Pune, offers flexible workspaces, top-notch amenities, and a
                supportive environment for freelancers, startups, and
                professionals. Key features include:
              </p>

              <div className="d-flex flex-column gap-4">
                <div className="d-flex gap-3">
                  <i className="fa-regular fa-circle-dot"></i>
                  <span>Tailored to Your Needs</span>
                </div>
                <div className="d-flex gap-3">
                  <i className="fa-regular fa-circle-dot"></i>
                  <span>Flexible Lease Terms</span>
                </div>
                <div className="d-flex gap-3">
                  <i className="fa-regular fa-circle-dot"></i>
                  <span>Scalable Spaces</span>
                </div>
                <div className="d-flex gap-3">
                  <i className="fa-regular fa-circle-dot"></i>
                  <span>Hassle-Free Setup</span>
                </div>
                <div className="d-flex gap-3">
                  <i className="fa-regular fa-circle-dot"></i>
                  <span>Personalised Service</span>
                </div>
              </div>

              <div className="d-flex justify-content-lg-start justify-content-md-start justify-content-sm-center justify-content-center">
                <a href="/contact#contact-form" className="hover1">
                  Book A Tour
                </a>
              </div>
            </div>
            <div
              className="col-lg-6 col-md-6 position-relative"
              data-aos="fade-up"
            >
              <div className="about-circle"></div>
              <div className="about-image1">
                <figure>
                  <img
                    src="assets/images/about/about-image1.png"
                    alt="girl-image"
                  />
                </figure>
              </div>
              <div className="about-image2">
                <figure>
                  <img
                    src="assets/images/about/about-image2.png"
                    alt="girl-image"
                  />
                </figure>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Features />
      <Amenities />
      <section className="find">
        <div className="container">
          <div className="row">
            <div
              className="col-lg-6 col-md-6 d-flex flex-column align-items-md-start align-items-sm-center align-items-center mb-3"
              data-aos="fade-down"
            >
              <h2>
                Come & Find Your Favourite <span>Place</span>
              </h2>
              {/* <p className="find-p">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text.
              </p> */}
              <a href="/contact#contact-form" className="hover1  mt-2">
                Book a tour today!
              </a>
            </div>

            <div className="col-lg-6 col-md-6 position-relative ">
              <div>
                <ul className="list-group">
                  <li className="list-group-item pb-3">
                    Discover your ideal workspace at BestWorks. Our modern
                    coworking space offers a variety of options to suit your
                    needs, from private cabins to shared desks.
                  </li>
                  <li className="list-group-item pb-3">
                    Enjoy a supportive and collaborative community of
                    like-minded professionals.
                  </li>
                  <li className="list-group-item pb-3">
                    Benefit from our flexible lease terms and hassle-free setup.
                  </li>
                  <li className="list-group-item pb-3">
                    Experience the convenience of our prime location in Kondhwa,
                    Pune.
                  </li>
                  <li className="list-group-item pb-3">
                    Take advantage of our top-notch amenities and exceptional
                    customer service.
                  </li>
                </ul>
              </div>
            </div>
            <div id="Succes-box2"></div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default withLayout(About);
